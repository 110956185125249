// import moment from "moment";

// import "moment/locale/en";

export const toClassName = (...values: (boolean | string | undefined)[]) => {
  return values.filter(Boolean).join(" ");
};

// export const toDate = (
//   date: any,
//   options?: { locale?: string; format?: string },
// ) => {
//   moment.locale(options?.locale ?? "en");
//   return moment(date).format(options?.format ?? "D MMMM YYYY");
// };
