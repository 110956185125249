const styles = {
  container: "fixed z-50 left-0 right-0 top-0 text-white duration-300",
  content:
    "flex flex-row justify-between items-center w-full gap-x-8 py-6 lg:py-10",

  head: "",
  body: "hidden sm:flex flex-row items-center gap-x-8 flex-grow text-base ml-8",
  foot: "",
};

export default styles;
