"use client";

import Image from "next/image";
import React from "react";

import { toClassName } from "helpers/format";

import { TNextClientComponent } from "types/next";

import { useScrollPosition } from "hooks/useScrollPosition";

import { Button } from "components/tailwind-ui/Button";
import Container from "components/tailwind-ui/Container";

import { styles } from ".";

type TProps = {
  lang?: string;
};

const Navbar: TNextClientComponent<TProps> = () => {
  const scrollPosition = useScrollPosition();

  const routes = [
    { id: "what-we-do", label: "What we do", href: "#what-we-do" },
    { id: "who-we-are", label: "Who we are", href: "#who-we-are" },
    { id: "what-we-offer", label: "What we offer", href: "#what-we-offer" },
  ];

  return (
    <div
      className={toClassName(
        styles.container,
        scrollPosition > 1
          ? "bg-[#1C1C1C] border-b border-[rgba(255,255,255,0.1)]"
          : "bg-[#1C1C1C] sm:bg-transparent border-[#1C1C1C]",
      )}
    >
      <Container type="center">
        <div className={styles.content}>
          <div>
            <a href="#introduction">
              <Image
                src="/assets/images/logo-8.svg"
                width={130}
                height={50}
                alt="logo"
                className="w-20 lg:w-36"
              />
            </a>
          </div>
          <ul className={styles.body}>
            {routes.map((route) => (
              <li key={route.id}>
                <a
                  href={route.href}
                  className="opacity-60 hover:opacity-100 duration-200"
                >
                  {route.label}
                </a>
              </li>
            ))}
          </ul>
          <div>
            <a href="#get-in-contact">
              <Button>Contact us</Button>
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Navbar;
